@import "reset";

body {
    font-family: Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 700;
    background: #fff;
    padding-top: 55px;
}

/*
** Header
*/
.app-nav {
    height: 50px;
    padding: 0 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.userinfo {
    color: black;
    margin-right: 10px;

    i {
        font-size: 18px;
        margin-right: 5px;
    }
}

.btn-gn {
    border-radius: 8px;
    border: 1px solid #dadada;
    margin-right: 5px;
}

.btn-outline-subscribe {
    background-color: #00a651;
    color: white !important;
}

/*
** Newsletter
*/
.newsletter-banner-headline {
    color: #4A4A4A;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: -1.4px;
    line-height: 64px;
    margin: 60px 0 0 0px;

    @media (max-width: 1024px) {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.52px;
        line-height: 28px;
        padding-left: 0;
        margin-top: 30px;
    }

    span {
        color: #0D59D5;
        font-size: 64px;
        font-weight: 500;
        letter-spacing: -1.4px;
        line-height: 65px;

        @media (max-width: 1024px) {
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -0.52px;
            line-height: 28px;
            padding-left: 0px;
        }
    }
}

.newsletter-banner-subheadline {
    color: #000000;
    font-size: 20px;
    letter-spacing: -0.44px;
    line-height: 24px;
    padding-top: 15px;

    @media screen and (max-width: 1024px) {
        font-size: 18px;
        letter-spacing: -0.44px;
        line-height: 21px;
        padding-left: 0px;
    }
}

.filter-category-menu {
    border-radius: 8px;
    background-color: #EBEBEB;
    margin-bottom: 10px;
    height: 48px;

    .category-name {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        display: inline-block;
        margin-left: 10px;
        width: 145px;
    }

    & label {
        cursor: pointer;
        width: 50px;
        height: 24px;
        background-color: #FFF;
        display: inline-block;
        border-radius: 100px;
        position: relative;
    }

    & label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 14px;
        height: 15px;
        background-color: #ccc;
        border-radius: 90px;
        transition: 0.3s;
    }

    & input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    & input:checked + label {
        background-color: #fff;
    }

    & input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
        background-color: #0D59D5;
    }
}

.filter-category-show-all {
    background-color: transparent;

    & label {
        background-color: #ebebeb !important;
    }
}

.title-section {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    margin-left: 13px;
    margin-bottom: 13px;
    display: block;
}

.newsletter-card {
    width: 18rem;

    @media (max-width: 576px) {
        width: 100%;
    }

    .card-image {
        position: relative;

        .product-img {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 30px;
            height: 30px;
        }
    }

    .card-title {
        color: #000 !important;
        font-size: 18px;
        font-weight: bold;
        line-height: 20px;

        a {
            color: #000 !important;
            text-decoration-line: none !important;
        }
    }

    .card-text {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        margin-top: 5px;
        margin-bottom: 15px;
        text-align: left;
    }

    .card-footer {
        background-color: transparent;
        border: none;
    }
}

.btn-dar-baja {
    color: #000;
    background-color: #ebebeb;

    &:hover {
        color: #000;
    }
}

.btn-registrame {
    color: #fff;
    background-color: #0d59d5;

    &:hover {
        color: #fff;
    }
}

/* .invalid class prevents CSS from automatically applying */
.invalid input:required:invalid {
    color: #BE4C54;

    a {
        color: #BE4C54;
    }
}

/* Mark valid inputs during .invalid state */
.invalid input:required:valid ~ {
    color: inherit !important;
}

.newsletter-detail-container {
    padding: 4rem 2rem;
}

.newsletter-detail-content {
    padding: 0 2rem;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
}

.jumbotron-newsletter {
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-position: 50%;
    background-color: transparent;
    color: white;
    height: 350px;
    padding: 0;
}

.jumbotron-over {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    height: 100%;

    h1, p {
        font-weight: 500;
    }

    .btn-ver-ejemplo {
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff !important;
        color: #000 !important;
        font-size: 16px !important;
        font-weight: bold !important;
        padding: 11px;

        &:hover {
            text-decoration: none;
        }
    }
}

.modal-inline {
    .modal-content {
        border: none;

        .modal-body {
            padding: 1rem 1rem 1rem 0;
        }

        .modal-header {
            display: none;
        }
    }
}

/*
** Profile
*/
.form-profile {
    label {
        margin-bottom: 5px;
    }

    label.error {
        color: red;
        width: 100%;
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 12px;
    }
}

.tabs {
    &:after, &:before {
        display: table;
        line-height: 0;
        content: "";
    }

    li {
        float: left;
    }
}

.vanilla-flat-tabs {
    float: right;
    font-size: 11px;
    font-weight: normal;
    color: #525252;
    text-transform: uppercase;
    margin: 0 0 21px;

    li {
        margin: 0 10px;
        height: 22px;
        line-height: 22px;

        &:last-child {
            margin: 0 5px 0 11px;
        }

        .tab {
            cursor: pointer;
        }
    }

    li.active {
        border-bottom: 2px solid #89dc7b;
    }
}

.scrollable-content {
    .vanilla-flat-tabs {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .vanilla-flat-tabs {
        height: 35px;
        margin-top: 0;
        margin-bottom: 0;

        li {
            position: relative;
            height: 35px;
        }
    }
}

.sbl-circ {
    height: 48px;
    width: 48px;
    color: #5a5a5a;
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.returnTo {
    font-size: 11px;
    font-weight: normal;
    color: #525252;
    text-transform: uppercase;
    margin: 15px 0;
}

// Page Not Found
#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 710px;
    width: 100%;
    padding-left: 190px;
    line-height: 1.4;
}

.notfound .notfound-404 {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
}

.notfound .notfound-404 h1 {
    font-family: 'Passion One', cursive;
    color: #00b5c3;
    font-size: 150px;
    letter-spacing: 15.5px;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound h2 {
    font-family: 'Raleway', sans-serif;
    color: #292929;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: 0;
}

.notfound p {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.notfound a {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #fff;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #292929;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    color: #fff;
    background-color: #00b5c3;
}

@media only screen and (max-width: 480px) {
    .notfound {
        text-align: center;
    }

    .notfound .notfound-404 {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
    }

    .notfound {
        padding-left: 15px;
        padding-right: 15px;
    }
}

// Error 500
$light-text-color: #C8FFF4;
$dark-text-color: #03DAC6;

.body-500 {
    background: linear-gradient(#111, #333, #111);
    background-repeat: no-repeat;
    background-size: cover;
    color: #eee;
    position: relative;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;

    .message {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        h1, h2, h3 {
            margin: 0;
            line-height: .8;
        }

        h2, h3 {
            font-weight: 300;
            color: $light-text-color;
        }

        h1 {
            font-weight: 700;
            color: $dark-text-color;
            font-size: 8em;
        }

        h2 {
            margin: 30px 0;
        }

        h3 {
            font-size: 2.5em;
        }

        h4 {
            display: inline-block;
            margin: 0 15px;
        }

        button {
            background: transparent;
            border: 2px solid $light-text-color;
            color: $light-text-color;
            padding: 5px 15px;
            font-size: 1.25em;
            transition: all 0.15s ease;
            border-radius: 3px;
        }

        button:hover {
            background: $dark-text-color;
            border: 2px solid $dark-text-color;
            color: #111;
            cursor: pointer;
            transform: scale(1.05);
        }
    }
}
